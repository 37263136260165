import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { ShowtoastSuccess, Showtoast } from "../../helpers/utils";
import { getOrderHistoryDataAction } from "./getOrderHistory";

const initialFinalValidationState = {
  isLoading: false,
  errMsg: "",
  validationData: "",
};

const finalValidationSlice = createSlice({
  name: "Final Validation Slice",
  initialState: initialFinalValidationState,
  reducers: {
    finalValidationRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.validationData = "";
    },
    finalValidationSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.validationData = action.payload;
    },
    finalValidationFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.validationData = "";
    },

    resetFinalValidationFailure(state) {
      state.isLoading = false;
      state.isError = false;
      state.boqData = null;
    },
  },
});

export const finalValidationAction = (url, data, typeName, orderHistoryData , navigate) => {
  return (dispatch) => {
    dispatch(finalValidationActions.finalValidationRequested());
    makeHttpRequest({
      method: "post",
      url: url,
      data: data,
      typeName: typeName,
      navigate
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            finalValidationActions.finalValidationSuccess(
              res?.data?.message
            )
          );
          if (typeName === 'cplsubcph') {
            dispatch(getOrderHistoryDataAction({ id: data.orderId, navigate }));
          }
          ShowtoastSuccess(res?.data?.message);
        } else {
          dispatch(
            finalValidationActions.finalValidationFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          navigate("/");
        }
      });
  };
};

export const resetFinalValidationData = () => (dispatch) => {
  dispatch(finalValidationActions.resetFinalValidationFailure());
};

export const finalValidationActions = finalValidationSlice.actions;
export default finalValidationSlice.reducer;
