import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { ShowtoastSuccess, Showtoast } from "../../helpers/utils";

const initialSyncDataState = {
  isLoading: false,
  errMsg: "",
  syncData: "",
};

const syncDataSlice = createSlice({
  name: "Sync Data Slice",
  initialState: initialSyncDataState,
  reducers: {
    syncDataRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.syncData = "";
    },
    syncDataSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.syncData = action.payload;
    },
    syncDataFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.syncData = "";
    },
  },
});

export const syncDataAction = (data, navigate) => {
  return (dispatch) => {
    dispatch(syncDataActions.syncDataRequested());
    makeHttpRequest({
      method: "post",
      url: `/sync-data`,
      data: data,
      navigate
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            syncDataActions.syncDataSuccess(
              res?.data?.message
            )
          );
          ShowtoastSuccess(res?.data?.message);
        } else {
          dispatch(
            syncDataActions.syncDataFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          navigate("/");
        }
      });
  };
};

export const syncDataActions = syncDataSlice.actions;
export default syncDataSlice.reducer;
