const { createSlice } = require("@reduxjs/toolkit");
const { makeHttpRequest } = require("../apiService/apiService");
const { Showtoast, ShowtoastSuccess } = require("../../helpers/utils");

const initialTaskState = {
  isLoading: false,
  isError: false,
  assignPlanningLeadData: "",
};

const assignPlanningLeadSlice = createSlice({
  name: "Assign Planning Lead",
  initialState: initialTaskState,
  reducers: {
    assignPlanningLeadApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.assignPlanningLeadData = [];
    },
    assignPlanningLeadApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.assignPlanningLeadData = action.payload;
    },
    assignPlanningLeadApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.assignPlanningLeadData = [];
    },
  },
});

export const assignPlanningLeadDataAction = ({
  assignData,
  navigate,
  path,
}) => {
  return (dispatch) => {
    dispatch(assignPlanningLeadActions.assignPlanningLeadApiRequested());
    let url = "/order/assign-planning-lead";
    makeHttpRequest({ method: "post", url: url, data: assignData, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            assignPlanningLeadActions.assignPlanningLeadApiSuccess(
              res?.data?.message
            )
          );
          ShowtoastSuccess(res?.data?.message);
          navigate(path);
        } else {
          dispatch(
            assignPlanningLeadActions.assignPlanningLeadApiFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          assignPlanningLeadActions.assignPlanningLeadApiFailure(
            err?.response?.data?.error
          )
        );
        Showtoast(err?.response?.data?.error, "error");
      });
  };
};

export const assignPlanningLeadActions = assignPlanningLeadSlice.actions;
export default assignPlanningLeadSlice.reducer;
