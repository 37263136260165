import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { ShowtoastSuccess, Showtoast } from "../../helpers/utils";

const initialBOQFormDataState = {
  isLoading: false,
  errMsg: "",
  boqFormData: "",
};
const updateBOQFormDataSlice = createSlice({
  name: "Update BOQFormData Slice",
  initialState: initialBOQFormDataState,
  reducers: {
    updateBOQFormDataApiRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.boqFormData = "";
    },
    updateBOQFormDataApiSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.boqFormData = action.payload;
    },
    updateBOQFormDataApiFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.boqFormData = "";
    },
  },
});

export const updateBOQFormDataApiAction = (data, navigate, path) => {
  console.log("data", data);
  return (dispatch) => {
    dispatch(updateBOQFormDataActions.updateBOQFormDataApiRequested());
    makeHttpRequest({
      method: "post",
      url: `/update-boq-form-data`,
      data: data,
      navigate,
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            updateBOQFormDataActions.updateBOQFormDataApiSuccess(
              res?.data?.message
            )
          );
          navigate(path);
          ShowtoastSuccess(res?.data?.message);
        } else {
          dispatch(
            updateBOQFormDataActions.updateBOQFormDataApiFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          navigate("/");
        }
      });
  };
};

export const updateBOQFormDataActions = updateBOQFormDataSlice.actions;
export default updateBOQFormDataSlice.reducer;
