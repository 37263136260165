import React from "react";
import { Route, Routes } from "react-router-dom";
import LazyLoading from "../components/LazyLoading";
import PageNotFound from "../components/PageNotFound";
import ServerError from "../components/ServerError";
import SsoOathRedirectPage from "../containers/OathRedirectPage";

const LoginPages = LazyLoading(() => import("../containers/LoginPage"));
const RoleManagement = LazyLoading(() =>
  import("../containers/RoleManagements")
);
const UserManagement = LazyLoading(() =>
  import("../containers/UserManagments")
);
const MasterDataManagement = LazyLoading(() =>
  import("../containers/MasterDataManagement")
);
const TaskManagement = LazyLoading(() =>
  import("../containers/TaskManagement")
);
const TaskManagementsTable = LazyLoading(() =>
  import("../containers/TaskManagement/components/TaskManagementsTable")
);
const ProtectedRoutes = LazyLoading(() => import("./ProtectedRoutes"));
const TaskManagementDetails = LazyLoading(() =>
  import("../containers/TaskManagement/components/TaskManagementDetails")
);
const AssignCirclePlanningHeadLead = LazyLoading(() =>
  import("../containers/TaskManagement/components/AssignCirclePlanningHeadLead")
);
const BOQForm = LazyLoading(() =>
  import("../containers/TaskManagement/components/BOQForm")
);
const AssignSurveyorList = LazyLoading(() =>
  import("../containers/TaskManagement/components/AssignSurveyorList")
);

const FeasibiltyPage = LazyLoading(() =>
  import("../containers/FeasiblityModule")
);

const Routing = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes redirectTo="/" />}>
        <Route path="/role-management" element={<RoleManagement />} />

        <Route
          path="/master-data-management"
          element={<MasterDataManagement />}
        />

        <Route path="/user-management" element={<UserManagement />} />

        <Route path="/order-management" element={<TaskManagementsTable />} />

        <Route path="/order-locality-management" element={<TaskManagement />} />
        <Route
          path="/order-management-details"
          element={<TaskManagementDetails />}
        />
        <Route
          path="/assign-circle-planning-lead"
          element={<AssignCirclePlanningHeadLead />}
        />
        <Route path="/update-boq-form" element={<BOQForm />} />
        <Route
          path="/assign-surveyor-lists"
          element={<AssignSurveyorList />}
        />
      </Route>

      <Route path="/" element={<LoginPages />} />
      <Route path="/feasibility-module" element={<FeasibiltyPage />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/server-error" element={<ServerError />} />
      <Route path="/oauth2redirect" element={<SsoOathRedirectPage />} />
    </Routes>
  );
};

export default Routing;
