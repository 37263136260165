import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, encryptUsingAES256 } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";

const initialOrderHistory = {
  isLoading: false,
  isError: false,
  orderHistoryData: [],
};

const getOrderHistorySlice = createSlice({
  name: "Get Order History Data",
  initialState: initialOrderHistory,
  reducers: {
    orderHistoryApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.orderHistoryData = [];
    },
    orderHistoryApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.orderHistoryData = action.payload;
    },
    orderHistoryApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.orderHistoryData = [];
    },
    orderHistoryApiReset(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.orderHistoryData = [];
    },
  },
});

export const getOrderHistoryDataAction = ({ navigate, id }) => {
  return (dispatch) => {
    dispatch(GetOrderHistoryDataAction.orderHistoryApiRequested());
    let url = `/order/history?orderId=${encryptUsingAES256(
      JSON.stringify(id)
    )}`;
    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            GetOrderHistoryDataAction.orderHistoryApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(
            GetOrderHistoryDataAction.orderHistoryApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          GetOrderHistoryDataAction.orderHistoryApiFailure(
            err?.response?.data?.message
          )
        );
      });
  };
};

export const resetOrderHistoryData = () => (dispatch) => {
  dispatch(GetOrderHistoryDataAction.orderHistoryApiReset());
};

export const GetOrderHistoryDataAction = getOrderHistorySlice.actions;
export default getOrderHistorySlice.reducer;
