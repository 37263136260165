import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, accessKeys, encryptUsingAES256, generateRandomKey } from "../../helpers/utils";
import secureLocalStorage from "react-secure-storage";
import { makeHttpRequest } from "../apiService/apiService";
import { otpActionPost } from "../resetPassword/resetPassword";

const initialAuthState = {
  isAuthenticated: false,
  isLoading: false,
  isError: "",
  authRes: {},
  isRegistered: false,
  RegRes: null,
  isOTPSent: false,
  isOTPResend: false,
};

let customKeyValue = generateRandomKey();

const loginSlice = createSlice({
  name: "login",
  initialState: initialAuthState,
  reducers: {
    loginAPIRequested(state) {
      state.isLoading = true;
      state.isAuthenticated = false;
    },
    loginAPISuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.authRes = action.payload;
    },
    loginAPIFailure(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isError = action.payload;
    },
  },
});

export const userAuthentication = (
  data,
  setShow,
  navigate,
  setMinutes,
  setSeconds,
) => {
  const randomKey = generateRandomKey();
  return (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    makeHttpRequest({
      method: "post",
      url: `/log-in`,
      data: { ...data, customDate: customKeyValue },
      headers: { Authorization: null },
      navigate,
      randomKey,
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          if (response?.data?.data?.customDate != customKeyValue) {
            Showtoast("Something Went Wrong");
            dispatch(authActions.loginAPIFailure(""));
          } else {
            dispatch(authActions.loginAPISuccess(response?.data?.data));
            secureLocalStorage.setItem(
              "loginresponse",
              JSON.stringify(response?.data?.data)
            );
            sessionStorage?.removeItem("toast");
            setShow(true);
            dispatch(
              otpActionPost(
                { mobileNumber: data?.mobileNumber },
                setShow,
                navigate,
                setMinutes,
                setSeconds
              )
            );
          }
        } else {
          if (response?.response?.status === 401) {
            return;
          }
          dispatch(authActions.loginAPIFailure(response?.data?.message));
          Showtoast(response?.data?.message);
        }
      })
      .catch((error) => {
        dispatch(authActions.loginAPIFailure(error));
      });
  };
};

export const userAuthenticationBySso = (
  tokenCode,
  navigate
) => {
  const randomKey = generateRandomKey();
  return (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    makeHttpRequest({
      method: "post",
      url: `/login-by-sso`,
      data: { code: tokenCode, customDate: customKeyValue },
      headers: { Authorization: null },
      navigate,
      randomKey
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          if (response?.data?.data?.customDate != customKeyValue) {
            Showtoast("Something Went Wrong");
            dispatch(authActions.loginAPIFailure(""));
          } else {
            dispatch(authActions.loginAPISuccess(response?.data?.data));
            const loginResponse = response?.data?.data;
            if (loginResponse) {
              secureLocalStorage?.setItem("loginResponse", JSON.stringify(loginResponse));
              secureLocalStorage?.setItem("loginBySso", true);
              const userRoles = loginResponse?.navigations;
              if (userRoles?.includes(accessKeys?.viewRoleListing)) {
                navigate("/role-management");
              } else if (userRoles?.includes(accessKeys?.viewUserListing)) {
                navigate("/user-management");
              } else if (userRoles?.includes(accessKeys?.viewTaskListing)) {
                navigate("/order-management");
              } else if (userRoles?.includes(accessKeys?.viewMasterListing)) {
                navigate("/master-data-management");
              }
            }
          }
        } else {
          dispatch(authActions.loginAPIFailure(response?.data?.message));
          Showtoast(response?.data?.message);
          navigate("/");
        }
      })
      .catch((error) => {
        dispatch(authActions.loginAPIFailure(error));
      });
  };
};

export const authActions = loginSlice.actions;

export default loginSlice.reducer;
