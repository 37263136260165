import { createSlice } from "@reduxjs/toolkit";
import { Showtoast } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";

const initialMasterDataState = {
  isLoading: false,
  isError: false,
  masterData: [],
};

const getMasterDataSlice = createSlice({
  name: "Get Master Data",
  initialState: initialMasterDataState,
  reducers: {
    masterDataApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.masterData = [];
    },
    masterDataApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.masterData = action.payload;
    },
    masterDataApiFailure(state) {
      state.isLoading = false;
      state.isError = true;
      state.masterData = [];
    },
  },
});

export const getMasterDataAction = ({ navigate }) => {
  return (dispatch) => {
    dispatch(getMasterDataActions.masterDataApiRequested());
    let url = `/master-data`; // Modify the endpoint according to your API
    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            getMasterDataActions.masterDataApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(getMasterDataActions.masterDataApiFailure());
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(getMasterDataActions.masterDataApiFailure());
        Showtoast(err?.response?.data?.message, "error");
      });
  };
};

export const getMasterDataActions = getMasterDataSlice.actions;
export default getMasterDataSlice.reducer;
