import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { commonDateFormat, commonTimeFormat } from "../utils";

const headerSheet = {
  errorReport: [
    "Name",
    "EmailId",
    "MobileNumber",
    "OlmId",
    "L1_OlmId",
    "Circle",
    "Role",
    "Error Message",
  ],
  template: [
    "Name",
    "EmailId",
    "MobileNumber",
    "OlmId",
    "L1_OlmId",
    "Circle",
    "Role",
  ],
  user: [
    "Team",
    "Role",
    "Circle",
    "Name",
    "OLM ID",
    "Phone Number",
    "Email ID",
    "Status",
  ],
  task: [
    "Order ID",
    "Locality Name",
    "Created On",
    "Created By",
    "Circle",
    "Last Updated On",
    "History Status",
  ],
  masterData: {
    "Circle File Management": [
      "Circle Name",
      "Cirle Abbreviation",
      "Last Updated On",
      "KML File",
    ],
    "Master BOQ": [
      "Circle Name",
      "Cirle Abbreviation",
      "FAT BOX",
      "ODF",
      "OLT",
      "Fibre Length",
      "Threshold Homepass Count",
    ],
  },
};
const fileNameLabel = {
  errorReport: "Bulk Upload Error Report",
  template: "Bulk Upload Template",
  user: "FTTH User Details",
  task: "FTTH Task Details",
  masterData: "FTTH Master Data Details",
};
const handleExcelData = (data, name, activeTab) => {
  const mapErrorReport = (item) => {
    return [
      item?.name,
      item?.emailId,
      item?.mobileNumber,
      item?.olmId,
      item?.reportingLeadOlmId,
      item?.circleName,
      item?.role,
      item?.reasonOfFailure,
    ];
  };
  const mapTemplate = (item) => {
    return [
      item?.name,
      item?.emailId,
      item?.mobileNumber,
      item?.olmId,
      item?.reportingLeadOlmId,
      item?.circleName,
      item?.role,
    ];
  };
  const mapUser = (item) => {
    const circles =
      item?.circles && item.circles.length > 0
        ? item.circles.map((circle) => circle.name).join(",")
        : "NA";

    const status = item?.status === "ACTIVE" ? "Active" : "In-Active";

    return [
      item?.teamName,
      item?.roleName,
      circles || "NA",
      item?.userName,
      item?.olmId,
      item?.mobileNumber,
      item?.email,
      status,
    ];
  };

  const mapTask = (item) => {
    return [
      item?.orderId,
      item?.localityName,
      item?.createdDate
        ? `${commonDateFormat(item.createdDate)} ${commonTimeFormat(
          item.createdDate
        )}`
        : "",
      item?.createdBy,
      item?.circleName,
      item?.lastUpdatedAt
        ? `${commonDateFormat(item.lastUpdatedAt)} ${commonTimeFormat(
          item.lastUpdatedAt
        )}`
        : "",
      item?.historyStatus,
    ];
  };

  const mapCircleFileManagement = (item) => {
    return [
      item?.circleName,
      item?.circleAbbreviation,
      item?.lastUpdatedDate
        ? `${commonDateFormat(item.lastUpdatedDate)} ${commonTimeFormat(
          item.lastUpdatedDate
        )}`
        : "",
      item?.filePath,
    ];
  };

  const mapMasterBOQ = (item) => {
    return [
      item?.circleName,
      item?.circleAbbreviation,
      item?.fatBoxCount,
      item?.odfCount,
      item?.oltCount,
      item?.fiberLength,
      item?.homePassCount,
    ];
  };

  switch (name) {
    case "template":
      return data?.map(mapTemplate);
    case "errorReport":
      return data?.map(mapErrorReport);
    case "user":
      return data?.map(mapUser);
    case "task":
      return data?.map(mapTask);
    case "masterData":
      switch (activeTab) {
        case "Circle File Management":
          return data?.map(mapCircleFileManagement);
        case "Master BOQ":
          return data?.map(mapMasterBOQ);
        default:
          return null;
      }
    default:
      return null;
  }
};

const ExcelFileDownload = (data, name, activeTab) => {
  const sheetData = handleExcelData(data, name, activeTab);
  function handleDownloadButtonClick() {
    const workbook = generateExcelData(name, activeTab);
    downloadExcelFile(workbook);
  }
  handleDownloadButtonClick();

  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `${fileNameLabel[name]}${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }

  function generateExcelData(name, activeTab) {
    const sheetName = "Sheet1";
    const workbook = XLSX.utils.book_new();
    const worksheet =
      name === "masterData"
        ? XLSX.utils.aoa_to_sheet([
          headerSheet.masterData[activeTab],
          ...sheetData,
        ])
        : XLSX.utils.aoa_to_sheet([headerSheet[name], ...sheetData]);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
};

export default ExcelFileDownload;
