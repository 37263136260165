import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";
import secureLocalStorage from "react-secure-storage";

const initialCircleState = {
  isLoading: false,
  isError: false,
  circleKMLFile: "",
};

const getCircleKMLFileSlice = createSlice({
  name: "Get KML FIle",
  initialState: initialCircleState,
  reducers: {
    apiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.circleKMLFile = "";
    },

    apiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.circleKMLFile = action.payload;
    },

    apiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.circleKMLFile = "";
    },
  },
});

export const getCircleKMLAction = (id, navigate) => {
  let baseUrl = process.env.REACT_APP_BACKEND_KML_URL;
  return (dispatch) => {
    dispatch(getCircleDataAction.apiRequested());
    makeHttpRequest({
      method: "get",
      url: `/circle-url?circleId=${encryptUsingAES256(JSON.stringify(id))}`,
      navigate,
    })
      .then((res) => {
        dispatch(getCircleDataAction.apiSuccess(res?.data?.data));
        localStorage.setItem(
          "kmlUrl",
          (baseUrl + res?.data?.data) +
            "?token=" +
            JSON.parse(secureLocalStorage?.getItem("loginResponse"))
              ?.bearerToken
        );
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(getCircleDataAction.apiFailure(err));
      });
  };
};

export const getCircleDataAction = getCircleKMLFileSlice.actions;
export default getCircleKMLFileSlice.reducer;
