import { makeHttpRequest } from "../apiService/apiService";
import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess } from "../../helpers/utils";

const initialTaskState = {
    isLoading: false,
    isError: false,
    bulkUpload: "",
};
const bulkUploadSlice = createSlice({
    name: "Bulk Upload",
    initialState: initialTaskState,
    reducers: {
        bulkUploadApiRequested(state) {
            state.isLoading = true;
            state.isError = false;
            state.bulkUpload = "";
        },
        bulkUploadApiSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.bulkUpload = action.payload;
        },
        bulkUploadApiFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.bulkUpload = "";
        },
    },
});

export const bulkUploadApiAction = (data, navigate, setShowTwo, setShowSecondModal, setBulkUploadErrorData, activeTab, circleData) => {
    return (dispatch) => {
        dispatch(bulkUploadActions.bulkUploadApiRequested());
        makeHttpRequest({ method: "post", url: "/bulk-upload", data, navigate, skipEncryption: true, })
            .then((res) => {
                if (res?.data?.status === "Success") {
                    dispatch(
                        bulkUploadActions.bulkUploadApiSuccess(res?.data?.message)
                    );

                    ShowtoastSuccess(res?.data?.message);
                    setShowTwo(false);
                } else {
                    dispatch(
                        bulkUploadActions.bulkUploadApiFailure(res?.data?.message)
                    );
                    setShowTwo(false);
                    setShowSecondModal(true);
                    setBulkUploadErrorData(res?.data?.data);
                }
            })
            .catch((err) => {
                dispatch(
                    bulkUploadActions.bulkUploadApiFailure("Something went wrong")
                );
                Showtoast("Something went wrong", "error");
                setShowTwo(false);
            });
    };
}

export const bulkUploadActions = bulkUploadSlice.actions;
export default bulkUploadSlice.reducer;