import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import ExcelFileDownload from "../../helpers/ExcelFileDownload";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const initialCircleState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  circleData: [],
};

const getCircleDataSlice = createSlice({
  name: "Get Circle Data",
  initialState: initialCircleState,
  reducers: {
    circleApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.circleData = [];
    },

    circleApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.circleData = action.payload;
    },

    circleApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.circleData = [];
    },
  },
});

export const getCircleDataAction = (
  data,
  navigate,
  activeTab,
  isDownload = false
) => {
  return (dispatch) => {
    if (!isDownload) {
      dispatch(CircleDataAction.circleApiRequested());
    }
    let url = "/circle/view";
    makeHttpRequest({ method: "post", url: url, data: data, navigate })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          if (isDownload) {
            ExcelFileDownload(
              res?.data?.data?.circleEntityList,
              "masterData",
              activeTab
            );
          } else {
            dispatch(CircleDataAction.circleApiSuccess(res?.data?.data));
          }
        } else {
          dispatch(CircleDataAction.circleApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(CircleDataAction.circleApiFailure(err));
      });
  };
};

export const CircleDataAction = getCircleDataSlice.actions;
export default getCircleDataSlice.reducer;
