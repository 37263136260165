import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAuthenticationBySso } from '../../store/login/auth';
import Loader from "../../components/Loading/Loading";
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SsoOathRedirectPage = () => {
    const dispatch = useDispatch();
const navigate = useNavigate();
  let query = useQuery();

  useEffect(() => {
    const code = query.get('code');
    dispatch(userAuthenticationBySso(code,navigate));
  }, [query]);

  return (
    <div className="d-flex justify-content-center align-items-center">
   <h1>OAuth2 Redirect</h1>
    <Loader />
  </div>
  );
};

export default SsoOathRedirectPage;
